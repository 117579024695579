import {AbstractControl, ValidationErrors} from '@angular/forms';
import {MsDate} from '@matchsource/date';

export const PAST_DATE_VALIDATOR_KEY = 'past-date';

export const pastDateValidator = (control: AbstractControl): ValidationErrors | null => {
  const isValid = !control.value || !new MsDate(control.value).isValid || !new MsDate(control.value).isFuture();

  return !isValid ? {[PAST_DATE_VALIDATOR_KEY]: {value: control.value}} : null;
};
